<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="dialogWidth">
      <template v-slot:title>
        {{ id ? data.address : "Новый объект" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <template v-if="![55, 58, 66, 67].includes($root.profile.role)">
        <v-row class="mt-1">
          <a-form-modelCols
            v-model="data"
            class="pb-3 mx-1 pt-2"
            :model="getFormModel((data.is_investor == 1 ? 'object_group#6,object_type#6,name' : 'object_group#3,object_type#6,plan_date_finish#3,name').split(','))"
            :values="data"
            :errors="errors"
            :config="{ dense: true, readonly, hideDetails: 'auto' }"
            @validate="
              validate($event);
              onObjectGroupChange($event);
            "
          />
        </v-row>
        <v-row class="mt-2" style="margin: 0 -16px">
          <v-col cols="12" sm="4" class="py-0 pt-1">
            <v-radio-group
              :readonly="readonly"
              class="mt-0 pt-0"
              v-model="data['is_investor']"
              :label="oModel['is_investor'].title"
              row
              :error-messages="errors['is_investor']"
              @change="onObjectTypeChange"
            >
              <v-radio label="Инвестор" :value="1"></v-radio>
              <v-radio label="Клиент" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" class="py-0 pt-1">
            <a-input-selectclient
              ref="client"
              :model="clientModel"
              v-model="data['client_id']"
              :values="data"
              :disabled="data.is_investor !== 0"
              :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
              @input="validate('client_id')"
              :error="errors['client_id']"
            />
          </v-col>

          <v-col cols="12" sm="4" class="py-0 pt-1">
            <a-input-selectapi
              :model="oModel['investor_id']"
              v-model="data['investor_id']"
              :values="data"
              :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
              :disabled="data.is_investor !== 1"
              @input="validate('investor_id')"
              :error="errors['investor_id']"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-0 pt-1">
            <a-form-model
              v-model="data"
              :model="getFormModel(['investment_amount'])"
              :errors="errors"
              :config="{ dense: true, disabled: data.is_investor !== 1 && false, readonly, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </v-col>
          <v-col cols="12" px-2 mb-3 v-show="data.is_investor === 1">
            <v-expansion-panels accordion v-model="panelPlan">
              <v-expansion-panel>
                <v-expansion-panel-header>Данные для планирования</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <a-form-modelCols
                    v-model="data"
                    class="pb-3"
                    :model="
                      getFormModel([
                        'credit_sum',
                        'data.investor_share',
                        'plan_sell_price',
                        'plan_expenses',
                        'credit_first_payment',
                        'plan_org_expenses',
                        'data.doc_buy_price',
                        'data.doc_sell_price',
                        'plan_month_expenses',
                        'data.donor_id',
                        'plan_investment_amount',
                        'data.is_procuratory',
                        'data.is_agreement_investor',
                        'data.is_agreement_donor',
                        'plan_date_finish',
                      ])
                    "
                    :values="data"
                    :errors="errors"
                    :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
                    @validate="validate($event)"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="!true && data.credit_sum && $root.profile.role == 1000">
                <v-expansion-panel-header dense>График выплат по кредиту</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <s-form-loan-schedule v-model="data.loan_schedule" :object="data" class="pb-3" @validate="validate($event)" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <a-form-modelCols
            v-if="data['buyer_id'] || data['ap_price_sell']"
            class="pb-3 mx-1 mt-2"
            v-model="data"
            :values="data"
            :model="getFormModel(['buyer_id#6', 'ap_price_sell#6'])"
            :errors="errors"
            :config="{ dense: true, readonly, hideDetails: 'auto' }"
            @validate="validate($event)"
          />
          <a-form-modelCols
            v-model="data"
            class="pb-3 mx-1 mt-2"
            :model="
              getFormModel([
                'city#4',
                'address#8',
                'residential_complex_id#8',
                'residential_type#4',
                'class#4',
                'type#4',
                'building_type#4',
                'ap_area_real#3',
                'ap_area_plan#3',
                'layout_legal#6',
                'hr',
                'data.cadastralNumber#12',
                'data.passengerElevator#3',
                'data.freightElevator#3',
                'data.balconies#3',
                'data.loggias#3',
                'data.wardrobe#3',
                'data.windows#3',
                'data.separateWcsCount#3',
                'data.combinedWcsCount#3',
                'data.parking',
                'hr',
              ])
            "
            :values="data"
            :errors="errors"
            :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
            @validate="validate($event)"
          />
        </v-row>
        <v-row class="mt-1">
          <v-col cols="12" px-2 pt-0 pb-5>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header dense>Настройка комнат на объекте</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <s-form-rooms-config v-model="data.data.rooms" :object="data" class="pb-3" @validate="validate($event)" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <a-form-modelCols
          v-model="data"
          class="pb-3 mx-1"
          :model="
            getFormModel(
              (
                'ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#3,ap_price_adv#3,ap_floor#2,building_floors#2,' +
                'ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout11#6,ap_view11#6,infrastructure11,nearby11,description,metro,' +
                'data.vendor,photos,photos_design,doc,doc_adv,doc_law,doc_furniture,tech_requirements,style_compilation,plan_solution,object_access'
              ).split(',')
            )
          "
          :values="data"
          :errors="errors"
          :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
          @validate="validate($event)"
        />
      </template>
      <template v-else>
        <v-layout px-2 py-2 mt-1 row wrap>
          <v-flex sm12 px-2 sm-pb-0>
            <a-input-images
              :model="oModel['photos_design']"
              v-model="data['photos_design']"
              :values="data"
              :config="{ dense: true, rating, readonly }"
              @input="validate('photos_design')"
              :error="errors['photos_design']"
            />
            <a-input-images
              :model="oModel['photos']"
              v-model="data['photos']"
              :values="data"
              :config="{ dense: true, rating, readonly }"
              @input="validate('photos')"
              :error="errors['photos']"
            />
            <a-form-model v-if="false" v-model="data" :model="getFormModel(['docs'])" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </v-flex>
        </v-layout>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :disabled="readonly" class="pr-2" :color="isChanged ? 'green' : 'primary'"></a-btn-save>

        <a-btn-delete v-if="id && deleteObject" :disabled="readonly" @click="removeDialogShow = true" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, libChecks, genModel } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, libChecks, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects,
      removeDialogShow: false,
      suEditShow: false,
      removeTitle: "Вы действительно хотите удалить этот объект?",
      oModel: {},
      submitKey: false,
      clientModelLocal: null,
      fieldsReq: {
        2: "name,city,address,type,ap_price_buy,ap_area,ap_kitchen_area,building_type,ap_rooms,ap_floor,building_floors,ap_number",
        3: "name,city,address,type,ap_price_buy,ap_area,ap_kitchen_area,building_type,ap_rooms,ap_floor,building_floors,ap_number",
        4: "name,city,address",
      },
      panelPlan: null,
    };
  },
  computed: {
    readonly() {
      if (!this.id) return false;
      return !this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    model() {
      let res = [];
      let model = this.getModelList(this.m, "form", true);
      for (const el of model) {
        if (![55, 58, 66, 67].includes(this.$root.profile.role) || ["photos_design", "photos", "doc"].includes(el.name)) {
          res.push(el);
        }
      }
      res.find((m) => m.name == "plan_date_finish").title = this.data.is_investor == 1 ? "Дата готовности" : "Дата сдачи";
      return res;
    },
    dialogWidth() {
      return (this.m?.dialogWidth || 800) + "px";
    },
    clientModel() {
      let model = {};
      model =
        this.clientModelLocal ||
        this.model.find((el) => {
          return el.name == "client_id";
        });
      return model;
    },
    rating() {
      return false;
    },
    deleteObject() {
      return this.getAccess("object.delete");
    },
  },
  watch: {
    "data.ap_price_buy"() {
      this.calcPrice_m2();
    },
    "data.ap_area"() {
      this.calcPrice_m2();
    },

    value(v) {
      if (this.value) {
        this.panelPlan = null;
        this.updateData(this.id);
      }
      this.submitKey = v;
    },
  },
  created() {
    this.makeObjectModel();
  },
  methods: {
    afterFetchData() {
      this.onObjectTypeBitChange(this.data.object_type);
    },
    onObjectGroupChange(e) {
      if (e !== "object_group") return;
      let res = 0;
      let val = this.data.object_group;
      if (val) {
        res = 1 << (val - 1);
      } else {
        res = 7;
      }
      this.data.object_type = res;
      this.onObjectTypeBitChange(res);
    },
    onObjectTypeBitChange(e) {
      if (e !== "object_type") return;
      let val = this.data.object_type;
      //this.validate("object_type");
      let bit = null;
      // 2-Дизайн,3-Ремонт,4-Мебель
      if (val & (1 << 0)) bit = 2;
      else if (val & (1 << 1)) bit = 3;
      else if (val & (1 << 2)) bit = 4;
      let model = this.model.find((el) => {
        return el.name == "client_id";
      });
      model.optionsFilter = {
        client_type: (v) => {
          return v & (1 << (bit - 1));
        },
      };
      if (bit) {
        this.validators = {};
        for (let el of this.model) {
          let val = (el.validator || []).filter((vv) => vv != "req");
          if (val.length) this.$set(this.validators, el.name, val);
        }
        for (let el of this.fieldsReq[bit].split(",")) {
          this.$set(this.validators, el, ["req", ...(this.validators?.[el] || [])]);
        }
      }
      this.clientModelLocal = model;
      if (this.$refs.client) {
        this.$refs.client.fetchData();
      }
    },

    onObjectTypeChange() {
      let res = 0;
      this.model.find((m) => m.name == "plan_date_finish").title = this.data.is_investor == 1 ? "Дата готовности" : "Дата сдачи";
      if (this.data.is_investor == 1) {
        let types =
          this.model.find((el) => {
            return el.name == "object_type";
          })?.options || [];

        types.forEach((type) => {
          res = res ^ (1 << (type.value - 1));
        });
      }
      this.data.object_type = res;
    },
    calcPrice_m2() {
      this.data.price_m2 = "";
      if (this.data.ap_area && this.data.ap_price_buy) {
        this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
      }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "objects" });
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    async _remove(api, id) {
      let countGoods = await this.getCount("/mechti/construction-goods", {
        object_id: this.id,
      });
      let countWorks = await this.getCount("/mechti/construction-schedule", {
        object_id: this.id,
      });
      if (countGoods == -1 || countWorks == -1) {
        this.$root.$emit("show-info", {
          text: "Ошибка соединения",
          type: "error",
        });
        return;
      }
      if (countGoods || countWorks) {
        this.$root.$emit("show-info", {
          text: "Есть связанные данные. Удаление невозможно!",
          type: "error",
        });
        return;
      }

      this.remove(api, id);
    },
  },
};
</script>
<style lang="sass">
.v-expansion-panel-content__wrap
  padding: 0 10px !important
</style>
